import React from 'react'
import { useState } from 'react'

export default function Movie(years) {
  const [theMovie, setThetMovie] = useState('')

  function getMovie(){
    setThetMovie(()=>{})
  }
console.log("sadasd")
  return (
    <div>Movie</div>
  )
}


