import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Nav from "./components/pages/Nav";
import Home from "./components/pages/Home";
import Movies from "./components/pages/Movies";
import Movie from "./components/pages/Movie";
import NoPage from "./components/pages/NoPage";

import "./assets/sass/index.scss";

import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./components/pages/Footer";

export default function App() {
  return (
    <Router>
      <Nav />
        <div className="content-list">
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/movie" element={<Movie />} />
        <Route path="/movies" element={<Movies />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
        </div>
      <Footer />
    </Router>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
