import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "../../assets/sass/footer.scss";

function Footer() {
  const d = new Date();
  let year = d.getFullYear();

  return (
    <Row>
        <div className="footer">
          <Col className="text-start"></Col>
          <Col className="text-center">{year} © Moviesdb</Col>
          <Col className="text-end">
            <small>v1.7.1</small>
          </Col>
        </div>
      
    </Row>
  );
}

export default Footer;
