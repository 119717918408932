import React, { Component } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import ReactPaginate from "react-paginate";

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "../../assets/sass/movies.scss";
import "../../assets/sass/pagination.scss";
import "../../assets/sass/card.scss";

export default class Movies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      offset: 0,
      data: [],
      perPage: 14,
      currentPage: 0,
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }
  receivedData() {
    axios
      .get(`https://moviesdb.fotografias360.com/api/api_movies.php`)
      .then((res) => {
        const data = res.data;
        const slice = data.slice(
          this.state.offset,
          this.state.offset + this.state.perPage
        );
        const postData = slice.map((mov) => (
          <React.Fragment>
            <Link
              to={mov.imdb}
              target="_blank"
              rel="noreferrer"
              alt={`Click to see more about this movie '` + mov.title + "'"}
              title={`About more of ` + mov.title}
            >
              <Card style={{border: 0, width: "14rem"}}>
                <Card.Img
                  variant="top"
                  src={mov.poster}
                  alt={`Poster of ` + mov.title}
                />
                <Card.Body>
                  <Card.Title>{mov.title}</Card.Title>
                  <Card.Text>
                    <Row>
                      <Col className="text-start">nº {mov.id}</Col>
                      <Col className="text-end">{mov.year}</Col>
                    </Row>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </React.Fragment>
        ));

        this.setState({
          pageCount: Math.ceil(data.length / this.state.perPage),
          postData,
        });
      });
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState(
      {
        currentPage: selectedPage,
        offset: offset,
      },
      () => {
        this.receivedData();
      }
    );
  };

  componentDidMount() {
    this.receivedData();
  }
  render() {
    return (
      <Container fluid>
        <Col className="text-center pt-5">
          <h2>All movies</h2>
        </Col>
        <Col>
          <div className="list grid gap-3 pt-5 pb-5">{this.state.postData}</div>
        </Col>
        <Col className="NavPagination mb-5">
          <ReactPaginate
            previousLabel={"<<"}
            nextLabel={">>"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </Col>
      </Container>
    );
  }
}
