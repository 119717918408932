import React, { useState, useEffect } from "react";

import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

function Menu() {
  const [years, setYears] = useState([]);

  const fetchData = () => {
    return fetch("https://moviesdb.fotografias360.com/api/api_years.php")
      .then((response) => response.json())
      .then((data) => setYears(data));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [open, setOpen] = useState(false);
  return (
    <>
      <Row>
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container>
            <Navbar.Brand>Moviedb</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/movies">Movies</Nav.Link>
                <Nav.Link
                  variant="light"
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                >
                  Years
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
            <Form inline>
              <Row>
                <Col xs="auto">
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    className=" mr-sm-2"
                  />
                </Col>
                <Col xs="auto">
                  <Button type="submit">Submit</Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </Navbar>
      </Row>
      <Row>
        <Collapse in={open}>
          <Col>
            {years &&
              years.length > 0 &&
              years.map((yearObj) => (
                <Button
                  size="sm"
                  variant="light"
                  className="m-1"
                  href={`/movie/` + yearObj.date}
                  key={yearObj.id}
                >
                  {yearObj.date}
                </Button>
              ))}
          </Col>
        </Collapse>
      </Row>
    </>
  );
}

export default Menu;
