import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

export default function NoPage() {
  return (
    <Container>
      <Row className="text-center lead m-5">
        <Col>
          <Image
            src="https://www.webhopers.com/wp-content/uploads/2017/02/How-to-Deal-with-the-404-Error-for-Search-Engine-Optimization.jpg"
            fluid
          />
        </Col>
      </Row>
      
    </Container>
  );
}
